@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: whitesmoke;
  font-family: Georgia, "Times New Roman", Times, serif;
}

/* Extra Space For Scrollbar */
.Extra-Space {
  margin-bottom: 12%;
}

/* Footer component */
.Footer {
  font-family: Georgia, "Times New Roman", Times, serif;
  background-color: whitesmoke;
  color: darkslategray;
  font-size: 1rem;
  height: 70px;
  margin-top: auto;
  text-align: center;
  justify-content: center;
  padding: 10px 0;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.Footer a {
  color: darkslategray;
  text-decoration: none;
}

.Footer a:hover {
  cursor: pointer;
  color: darkgray;
  text-decoration: none;
}

@media (max-width: 600px) {
  /* Extra Space For Scrollbar */
  .Extra-Space {
    margin-bottom: 18%;
  }
}
